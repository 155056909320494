/** @format */

.button {
  width: 100%;
  outline: none;
  border: none;
  background-color: #31424d;
  border-radius: 8px;
}

.title {
  font-family: SFProDisplay;
  display: block;
  color: #ffffff;
  padding: 15px 30px;
  cursor: pointer;
}
