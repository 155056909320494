/** @format */

.footer-buttons-container {
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-buttons-link {
  color: #31424d;
  font-family: SFProDisplay;
  cursor: pointer;
}
