/** @format */

@font-face {
  font-family: IndustryFont;
  src: url("./assets/Industry-UltraItalic.otf");
}

@font-face {
  font-family: SFProDisplay;
  src: url("./assets/SFProDisplay.ttf");
}

.app {
  width: 100%;
  max-width: 340px;
  margin: 24px auto;
  padding-bottom: 24px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
}

.app-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.app-header img {
  width: 100%;
}

.app-title {
  font-family: IndustryFont;
  font-size: 25px;
  padding: 12px 24px;
}

.form-container {
  padding: 12px 24px 0;
}

.field-wrapper {
  position: relative;
  margin-bottom: 16px;
}

.field-error {
  display: inline-block;
  font-size: 12px;
  padding-top: 4px;
  font-family: SFProDisplay;
  color: #f10;
}

.form-header {
  display: inline-block;
  margin-bottom: 14px;
  font-family: SFProDisplay;
}

.form-note {
  display: inline-block;
  margin-bottom: 14px;
  font-family: SFProDisplay;
  font-style: italic;
  font-size: 12px;
  font-weight: bold;
  color: #f10;
}


.terms {
  display: inline-block;
  color: rgba(0, 0, 0, 0.5);
  font-family: SFProDisplay;
  font-size: 12px;
  margin-top: 12px;
}

.field-icon {
  position: absolute;
  right: 20px;
  top: 12px;
}

.terms a,
.terms a:focus,
.terms a:hover {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: underline;
}

.input {
  border: none;
  background-color: #eee;
  border-radius: 8px;
  padding-left: 12px;
  height: 50px;
  width: 100%;
}

.hidden-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.upload-wrapper {
  position: relative;
  cursor: pointer;
}

.upload {
  position: absolute;
  right: 16px;
  top: 1px;
  font-family: SFProDisplay;
  cursor: pointer;
}

.has-helper-text {
  margin-bottom: 0;
}

.helper-text {
  font-size: 10px;
  margin-bottom: 12px;
}

/* https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_custom_checkbox */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  line-height: 28px;
  cursor: pointer;
  font-family: SFProDisplay;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 10px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
