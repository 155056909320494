/** @format */

@font-face {
  font-family: IndustryFont;
  src: url("../../assets/Industry-UltraItalic.otf");
}

.success-container {
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.success-title {
  font-family: IndustryFont;
  text-align: center;
}

.success-subtitle {
  display: block;
  font-size: 14px;
  text-align: center;
  margin: 16px auto 24px;
}

.store-badge {
  display: block;
  width: 150px;
  margin: 12px auto;
}
